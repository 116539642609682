/* Common */
.headerWrap {
  @apply fixed top-0 z-header h-[--fixed-top] w-full bg-background-1st shadow-[inset_0_-1px_0_0_var(--background-2nd)] playsee-mb:shadow-none;
}
.header {
  @apply relative mx-auto  h-[--fixed-top] w-full max-w-[--max-app-size] flex items-center px-[24px];

  @media screen(min768) {
    @apply px-[28px];
  }
  @media screen(min1080) {
    @apply px-[24px];
  }
}

:global(.playsee-mobile) .header {
  @apply px-0;
}

.logoLink {
  @media screen(min768) {
    @apply pl-[20px];
  }

  /* mobile layout */
  :global(.playsee-mobile) & {
    @apply pl-[16px];
  }
}

.logo {
  @apply h-[24px] w-[80px] fill-label-l1;
}

/* Spot feed */
.spotHeaderWrap {
  @apply fixed top-0 isolate z-header w-full;

  /* none mobile layout */
  html:not(:global(.playsee-mobile)) & {
    @apply h-[--fixed-top] bg-background-1st shadow-[inset_0_-1px_0_0_var(--background-2nd)];
  }

  /* mobile layout */
  :global(.playsee-mobile) & {
    @apply h-[160px] bg-transparent;

    &::before {
      content: '';
      @apply absolute left-0 top-0 z-0 h-[160px] w-full bg-gradient-feed-picker opacity-100;
    }

    &.showCard {
      @apply h-[--fixed-top] bg-background_still-1st;

      &::before {
        @apply hidden;
      }
    }

    .logo {
      @apply fill-label_still-l1;
    }
  }
}
